

















import { Component, Mixins, Vue } from 'vue-property-decorator'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LoginMethods from '@/components/organisms/LoginMethods.vue'

@Component({
  components: {
    InputWithLabel,
    ButtonBase,
  },
})
export default class Login extends Mixins(LoginMethods) {
  private gdlsCode = ''

  private created() {
    Vue.prototype.$gdlsCookies.setV2(false)
    Vue.prototype.$gdlsCookiesV3.setV3(false)
    this.mode = this.MODE.STUDENT
  }

  private async submitLogin() {
    this.login(this.gdlsCode)
  }
}
